/* eslint-disable jsx-a11y/anchor-is-valid */
import {useSelector, useDispatch} from 'react-redux'
import {changeSearch} from '../../../../../app/features/core/coreSlice'
import {KTIcon} from '../../../../helpers'

const ToolbarClassic = () => {
  const dispatch = useDispatch()
  const search = useSelector((state: any) => state.core.search)
  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div data-kt-search-element='form' className='w-100 position-relative mb-3'>
        <KTIcon
          iconName='magnifier'
          className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0'
        />
        <input
          type='text'
          className='form-control form-control-flush ps-10'
          name='search'
          value={search}
          onChange={({target: {value}}) => dispatch(changeSearch(value))}
          placeholder='Search...'
          data-kt-search-element='input'
        />
      </div>
    </div>
  )
}

export {ToolbarClassic}
