/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {
  calculateConsumableByResultPrice,
  calculateConsumableMaterialPrice,
  calculateCraftingCost,
  calculateMarketTax,
  calculateProfit,
  calculateTotalCost,
} from '../../../layout/utils'

type Props = {
  className: string
  craftingConsumable: {
    result: number
    materials: Array<{name: string; quantity: string; category: string}>
    category: string
    craftingCost: number
  }
  name: string
}

const TablesWidgetCrafting: React.FC<Props> = ({className, name, craftingConsumable}) => {
  const {category, result, materials, craftingCost} = craftingConsumable
  const consumable = useSelector((state: any) =>
    state.consumables[category].find((element: any) => element.name === name)
  )
  const collectables = useSelector((state: any) => state.collectables)
  const craftingCostDecrease = useSelector((state: any) => state.core.craftingCostDecrease)
  return (
    <div className={`${className}`}>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5' style={{justifyContent: 'normal'}}>
          <img
            src={toAbsoluteUrl(consumable.url)}
            className='align-self-center'
            style={{height: '90%', marginRight: '1rem'}}
            alt=''
          />
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{name}</span>
            <span className='text-muted fw-semibold fs-7'>{name} crafting details</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-5'>
              {/* begin::Table head */}
              <thead>
                <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-200px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {materials.map(
                  (
                    {quantity: materialQuantity, name: materialName, category: materialCategory},
                    index
                  ) => {
                    const collectable = collectables[materialCategory].find(
                      (element: any) => element.name === materialName
                    )
                    return (
                      <tr key={`table-tr-${index}`}>
                        <th>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label' style={{backgroundColor: 'transparent'}}>
                              <img
                                src={toAbsoluteUrl(collectable.url)}
                                className='align-self-center'
                                style={{height: '90%'}}
                                alt=''
                              />
                            </span>
                          </div>
                        </th>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {materialName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {materialQuantity}
                          </span>
                          <span className='text-muted fw-semibold d-block fs-7'>Quantity</span>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                              {calculateConsumableMaterialPrice(
                                collectable.bundle,
                                collectable.price,
                                parseInt(materialQuantity)
                              )}
                            </div>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              Total crafting material cost
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                )}
                {/* Crafting Cost */}
                <tr>
                  <th></th>
                  <td></td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Crafting Cost
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                        {calculateCraftingCost(craftingCost, craftingCostDecrease)}
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Tax */}
                <tr>
                  <th></th>
                  <td></td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Market Tax
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                        {calculateMarketTax(consumable, result)}
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Total */}
                <tr>
                  <th></th>
                  <td></td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Total Cost
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                        {calculateTotalCost(materials, calculateCraftingCost(craftingCost, craftingCostDecrease), collectables, calculateMarketTax(consumable, result))}
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Profit */}
                <tr>
                  <th></th>
                  <td></td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Profit per bundle crafted
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                        <span
                          style={{
                            color: calculateProfit(
                              calculateTotalCost(materials, calculateCraftingCost(craftingCost, craftingCostDecrease), collectables, calculateMarketTax(consumable, result)),
                              calculateConsumableByResultPrice(consumable, result)
                            ) < 0 ? 'red' : 'green'
                          }}
                        >
                          {calculateProfit(
                            calculateTotalCost(materials, calculateCraftingCost(craftingCost, craftingCostDecrease), collectables, calculateMarketTax(consumable, result)),
                            calculateConsumableByResultPrice(consumable, result)
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </div>
  )
}

export {TablesWidgetCrafting}
