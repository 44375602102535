import {createSlice} from '@reduxjs/toolkit'

export const collectablesSlice = createSlice({
  name: 'collectables',
  initialState: {
    fishing: [
      {
        name: 'Fish',
        bundle: 100,
        price: 100,
        url: '/images/fish.png',
      },
      {
        name: 'Natural Pearl',
        bundle: 10,
        price: 2,
        url: '/images/naturalPearl.png',
      },
      {
        name: 'Oreha Solar Carp',
        bundle: 10,
        price: 14,
        url: '/images/orehaSolarCarp.png',
      },
    ],
    foraging: [
      {
        name: 'Wild Flower',
        bundle: 100,
        price: 80,
        url: '/images/wildFlower.png',
      },
      {
        name: 'Shy Wild Flower',
        bundle: 10,
        price: 10,
        url: '/images/shyWildFlower.png',
      },
      {
        name: 'Bright Wild Flower',
        bundle: 10,
        price: 100,
        url: '/images/brightWildFlower.png',
      },
    ],
    mushrooms: [
      {
        name: 'Crude Mushroom',
        bundle: 100,
        price: 80,
        url: '/images/crudeMushroom.png',
      },
      {
        name: 'Fresh Mushroom',
        bundle: 10,
        price: 10,
        url: '/images/freshMushroom.png',
      },
      {
        name: 'Exquisite Mushroom',
        bundle: 10,
        price: 100,
        url: '/images/exquisiteMushroom.png',
      },
    ],
    logging: [
      {
        name: 'Timber',
        bundle: 100,
        price: 80,
        url: '/images/timber.png',
      },
      {
        name: 'Tender Timber',
        bundle: 10,
        price: 10,
        url: '/images/tenderTimber.png',
      },
      {
        name: 'Sturdy Timber',
        bundle: 10,
        price: 100,
        url: '/images/sturdyTimber.png',
      },
    ],
    mining: [
      {
        name: 'Iron Ore',
        bundle: 100,
        price: 80,
        url: '/images/ironOre.png',
      },
      {
        name: 'String Iron Ore',
        bundle: 10,
        price: 10,
        url: '/images/strongIronOre.png',
      },
      {
        name: 'Heavy Iron Ore',
        bundle: 10,
        price: 100,
        url: '/images/heavyIronOre.png',
      },
    ],
    excavating: [
      {
        name: 'Ancient Relic',
        bundle: 100,
        price: 80,
        url: '/images/ancientRelic.png',
      },
      {
        name: 'Rare Relic',
        bundle: 10,
        price: 10,
        url: '/images/rareRelic.png',
      },
      {
        name: 'Oreha Relic',
        bundle: 10,
        price: 100,
        url: '/images/orehaRelic.png',
      },
    ],
    hunting: [
      {
        name: 'Thick Raw Meat',
        bundle: 100,
        price: 80,
        url: '/images/thickRawMeat.png',
      },
      {
        name: 'Treated Meat',
        bundle: 10,
        price: 10,
        url: '/images/treatedMeat.png',
      },
      {
        name: 'Tough Leather',
        bundle: 10,
        price: 10,
        url: '/images/toughLeather.png',
      },
      {
        name: 'Oreha Thick Meat',
        bundle: 10,
        price: 100,
        url: '/images/orehaThickMeat.png',
      },
    ],
  },
  reducers: {
    changePrice: (state, {payload: {key, value, name}}) => {
      state[`${key}`] = state[key].map((element: {name: string}) => {
        return element.name === name
          ? {
              ...element,
              price: value,
            }
          : element
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const {changePrice} = collectablesSlice.actions

export default collectablesSlice.reducer
