import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Collectables' to='/collectables' />
      <MenuItem title='Consumables' to='/consumables' />
      <MenuItem title='Crafting' to='/crafting' />
    </>
  )
}
