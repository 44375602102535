/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {TablesWidget1} from '../../../_metronic/partials/widgets'
import {changePrice} from '../../features/collectables/collectablesSlice'
import {changeSearch} from '../../features/core/coreSlice'

const DashboardWrapper: FC = () => {
  const collectablesGroups = useSelector((state: any) => state.collectables)
  const search = useSelector((state: any) => state.core.search)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeSearch(''))
  }, [])
  const filteredCollectablesGroups = () => {
    if (search === '') return collectablesGroups;

    const keys = Object.keys(collectablesGroups);

    let result = {};
    keys.forEach((key) => {
      const filteredGroup = collectablesGroups[key].filter((group) => group.name.includes(search));
      if (filteredGroup.length) {
        result = {
          ...result,
          [key]: collectablesGroups[key]
        }
      }
    });
    return result;
  };
  const groups = filteredCollectablesGroups();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Collectables</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {Object.keys(groups).map((key, index) => {
          return (
            <TablesWidget1
              key={`table-${index}`}
              className={'col-sm-6'}
              collectableGroup={groups[key]}
              groupName={key}
              extension='collectables'
              onCollectableChange={(value: number, name: string) => {
                dispatch(changePrice({value, name, key}))
              }}
            />
          )
        })}
      </div>
    </>
  )
}

export {DashboardWrapper}
