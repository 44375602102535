import {createSlice} from '@reduxjs/toolkit'

export const craftingSlice = createSlice({
  name: 'crafting',
  initialState: {
    craftingConsumables: [
      {
        name: 'Oreha Fusion Material',
        result: 30,
        category: 'orehas',
        craftingCost: 205,
        materials: [
          {
            name: 'Fish',
            quantity: 80,
            category: 'fishing',
          },
          {
            name: 'Natural Pearl',
            quantity: 40,
            category: 'fishing',
          },
          {
            name: 'Oreha Solar Carp',
            quantity: 10,
            category: 'fishing',
          }
        ]
      },
      {
        name: 'Oreha Fusion Material',
        result: 30,
        category: 'orehas',
        craftingCost: 205,
        materials: [
          {
            name: 'Ancient Relic',
            quantity: 64,
            category: 'excavating',
          },
          {
            name: 'Rare Relic',
            quantity: 26,
            category: 'excavating',
          },
          {
            name: 'Oreha Relic',
            quantity: 8,
            category: 'excavating',
          }
        ]
      },
      {
        name: 'Oreha Fusion Material',
        result: 30,
        category: 'orehas',
        craftingCost: 205,
        materials: [
          {
            name: 'Thick Raw Meat',
            quantity: 80,
            category: 'hunting',
          },
          {
            name: 'Tough Leather',
            quantity: 40,
            category: 'hunting',
          },
          {
            name: 'Oreha Thick Meat',
            quantity: 10,
            category: 'hunting',
          }
        ]
      },
      {
        name: 'Superior Oreha Fusion Material',
        result: 20,
        category: 'orehas',
        craftingCost: 250,
        materials: [
          {
            name: 'Fish',
            quantity: 128,
            category: 'fishing',
          },
          {
            name: 'Natural Pearl',
            quantity: 64,
            category: 'fishing',
          },
          {
            name: 'Oreha Solar Carp',
            quantity: 16,
            category: 'fishing',
          }
        ]
      },
      {
        name: 'Superior Oreha Fusion Material',
        result: 20,
        category: 'orehas',
        craftingCost: 250,
        materials: [
          {
            name: 'Ancient Relic',
            quantity: 94,
            category: 'excavating',
          },
          {
            name: 'Rare Relic',
            quantity: 29,
            category: 'excavating',
          },
          {
            name: 'Oreha Relic',
            quantity: 16,
            category: 'excavating',
          }
        ]
      },
      {
        name: 'Superior Oreha Fusion Material',
        result: 20,
        category: 'orehas',
        craftingCost: 250,
        materials: [
          {
            name: 'Thick Raw Meat',
            quantity: 128,
            category: 'hunting',
          },
          {
            name: 'Tough Leather',
            quantity: 64,
            category: 'hunting',
          },
          {
            name: 'Oreha Thick Meat',
            quantity: 16,
            category: 'hunting',
          }
        ]
      },
      {
        name: 'Prime Oreha Fusion Material',
        result: 15,
        category: 'orehas',
        craftingCost: 300,
        materials: [
          {
            name: 'Fish',
            quantity: 142,
            category: 'fishing',
          },
          {
            name: 'Natural Pearl',
            quantity: 69,
            category: 'fishing',
          },
          {
            name: 'Oreha Solar Carp',
            quantity: 52,
            category: 'fishing',
          }
        ]
      },
      {
        name: 'Prime Oreha Fusion Material',
        result: 15,
        category: 'orehas',
        craftingCost: 300,
        materials: [
          {
            name: 'Ancient Relic',
            quantity: 107,
            category: 'excavating',
          },
          {
            name: 'Rare Relic',
            quantity: 51,
            category: 'excavating',
          },
          {
            name: 'Oreha Relic',
            quantity: 52,
            category: 'excavating',
          }
        ]
      },
      {
        name: 'Prime Oreha Fusion Material',
        result: 15,
        category: 'orehas',
        craftingCost: 300,
        materials: [
          {
            name: 'Thick Raw Meat',
            quantity: 142,
            category: 'hunting',
          },
          {
            name: 'Tough Leather',
            quantity: 69,
            category: 'hunting',
          },
          {
            name: 'Oreha Thick Meat',
            quantity: 52,
            category: 'hunting',
          }
        ]
      }
    ]
  },
  reducers: {},
})

export default craftingSlice.reducer
