import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx'
import {ThemeModeSwitcher} from '../../../partials'
import { changeCraftingCostDecrease } from '../../../../app/features/core/coreSlice';

const itemClass = 'ms-1 ms-md-4'

const Navbar = () => {
  const dispatch = useDispatch();
  const craftingCostDecrease = useSelector((state: any) => state.core.craftingCostDecrease);
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div style={{display: 'flex'}}>
          <span className="fw-bold">Crafting Cost Decrease</span>
          <input
            type='number'
            value={craftingCostDecrease}
            onChange={({ target: { value }}) => dispatch(changeCraftingCostDecrease(value))}
            className='form-control form-control-sm'
            style={{ marginRight: '2rem' }}
            name='search'
            placeholder='Crafting Cost Decrease'
            data-kt-search-element='input'
          />
        </div>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
    </div>
  )
}

export {Navbar}
