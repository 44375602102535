import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ConsumablesDashboardWrapper} from '../pages/dashboard/ConsumablesDashboardWrapper'
import {CraftingDashboardWrapper} from '../pages/dashboard/CraftingDashboardWrapper'

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path='collectables' element={<DashboardWrapper />} />
        <Route path='consumables' element={<ConsumablesDashboardWrapper />} />
        <Route path='crafting' element={<CraftingDashboardWrapper />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
