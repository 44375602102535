export const capitalize = (target: string) => {
  return `${target[0].toUpperCase()}${target.slice(1)}`
}

export const calculateMaterialUnitPrice = (bundle: number, price: number) => {
  return parseFloat((price / bundle).toFixed(2))
}

export const calculateConsumableMaterialPrice = (
  bundle: number,
  price: number,
  quantity: number
): number => {
  return parseFloat((calculateMaterialUnitPrice(bundle, price) * quantity).toFixed(2))
}

export const calculateConsumableByResultPrice = (consumable, result) => {
  return parseFloat((consumable.price * result).toFixed(2));
}

export const calculateMarketTax = (consumable, result) => {
  return Math.ceil((5 * consumable.price / 100)) * result;
}

export const calculateCraftingCost = (craftingCost, craftingCostDecrease) => {
  const parsedCraftingCostDecrease = parseInt(craftingCostDecrease || 0);
  const discount = parsedCraftingCostDecrease * craftingCost / 100;
  return parseInt((craftingCost - discount).toFixed(2));
}

export const calculateMaterialsTotalCost = (materials, collectables) => {
  return materials.reduce((acumulator: any, material: any) => {
    const collectable = collectables[material.category].find(
      (element: any) => element.name === material.name
    )
    return parseFloat(
      acumulator +
        calculateConsumableMaterialPrice(collectable.bundle, collectable.price, material.quantity)
    )
  }, 0)
}

export const calculateTotalCost = (materials: any, craftingCost: number, collectables: any, marketTax: any) => {
  return parseFloat((calculateMaterialsTotalCost(materials, collectables) + craftingCost + marketTax).toFixed(2))
}

export const calculateProfit = (totalCost, consumableByResultPrice) => {
  return parseFloat((consumableByResultPrice - totalCost).toFixed(2));
}
