/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {TablesWidgetCrafting} from '../../../_metronic/partials/widgets'
import { changeSearch } from '../../features/core/coreSlice'

const CraftingDashboardWrapper: FC = () => {
  const craftingConsumables = useSelector((state: any) => state.crafting.craftingConsumables)
  const search = useSelector((state: any) => state.core.search)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeSearch(''))
  }, [])
  const filteredCraftingConsumables = () => {
    if (search === '') return craftingConsumables;

    let result: Array<Object> = [];
    craftingConsumables.forEach((consumable) => {
      if (consumable.name.includes(search)) {
        result.push(consumable);
      }
    });
    return result;
  };
  const groups = filteredCraftingConsumables();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Crafting</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {groups.map((consumable, index) => {
          return (
            <TablesWidgetCrafting
              key={`crafting-table-${index}`}
              className={'col-sm-6'}
              craftingConsumable={consumable}
              name={consumable.name}
            />
          )
        })}
      </div>
    </>
  )
}

export {CraftingDashboardWrapper}
