/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {capitalize} from '../../../layout/utils'

type Props = {
  className: string
  groupName: string
  onCollectableChange: Function
  extension: string
  collectableGroup: Array<{
    name: string
    url: string
    bundle: number
    price: number
  }>
}

const TablesWidget1: React.FC<Props> = ({
  className,
  collectableGroup,
  groupName,
  onCollectableChange,
  extension,
}) => {
  return (
    <div className={`${className}`}>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{capitalize(groupName)}</span>
            <span className='text-muted fw-semibold fs-7'>
              {capitalize(groupName)} {extension}
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-5'>
              {/* begin::Table head */}
              <thead>
                <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-200px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {collectableGroup.map(({url, bundle, price, name}, index) => {
                  return (
                    <tr key={`table-tr-${index}`}>
                      <th>
                        <div className='symbol symbol-50px me-2'>
                          <span className='symbol-label' style={{backgroundColor: 'transparent'}}>
                            <img
                              src={toAbsoluteUrl(url)}
                              className='align-self-center'
                              style={{height: '90%'}}
                              alt=''
                            />
                          </span>
                        </div>
                      </th>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {name}
                        </span>
                        <span className='text-muted fw-semibold d-block fs-7'>
                          Sold in bundles of {bundle}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            <img src={toAbsoluteUrl('/images/gold.png')} alt='' />
                            <input
                              className='form-control form-control-sm'
                              style={{
                                width: '60%',
                                display: 'inline',
                              }}
                              onChange={({target: {value}}) => onCollectableChange(value, name)}
                              value={price}
                            />
                          </div>
                          <span className='text-muted fw-semibold d-block fs-7'>
                            Price per bundle
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </div>
  )
}

export {TablesWidget1}
