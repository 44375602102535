import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import collectablesSlice from './features/collectables/collectablesSlice'
import consumablesSlice from './features/consumables/consumablesSlice'
import coreSlice from './features/core/coreSlice'
import craftingSlice from './features/crafting/craftingSlice'

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  collectables: collectablesSlice,
  consumables: consumablesSlice,
  crafting: craftingSlice,
  core: coreSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware
});

export const persistor = persistStore(store);
