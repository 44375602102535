import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import { persistor, store } from "./store";
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={true} persistor={persistor}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <ThemeModeProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ThemeModeProvider>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export {App}
