import {createSlice} from '@reduxjs/toolkit'

export const coreSlice = createSlice({
  name: 'core',
  initialState: {
    craftingCostDecrease: 0,
    search: '',
  },
  reducers: {
    changeSearch: (state, { payload }) => {
      state.search = payload
    },
    changeCraftingCostDecrease: (state, { payload }) => {
      state.craftingCostDecrease = payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {changeCraftingCostDecrease, changeSearch} = coreSlice.actions

export default coreSlice.reducer
