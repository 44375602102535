import {createSlice} from '@reduxjs/toolkit'

export const consumablesSlice = createSlice({
  name: 'consumables',
  initialState: {
    potions: [
      {
        name: 'Major HP Potion',
        bundle: 1,
        price: 100,
        url: '/images/majorHpPotion.png',
      },
      {
        name: 'Elemental HP Potion',
        bundle: 1,
        price: 20,
        url: '/images/elementalHpPotion.png',
      },
      {
        name: 'Splendid Elemental HP Potion',
        bundle: 1,
        price: 14,
        url: '/images/splendidElementalHpPotion.png',
      },
    ],
    orehas: [
      {
        name: 'Oreha Fusion Material',
        bundle: 1,
        price: 100,
        url: '/images/orehaFusionMaterial.png',
      },
      {
        name: 'Superior Oreha Fusion Material',
        bundle: 1,
        price: 20,
        url: '/images/superiorOrehaFusionMaterial.png',
      },
      {
        name: 'Prime Oreha Fusion Material',
        bundle: 1,
        price: 14,
        url: '/images/primeOrehaFusionMaterial.png',
      },
    ],
    bombs: [
      {
        name: 'Destruction Bomb',
        bundle: 1,
        price: 100,
        url: '/images/destructionBomb.png',
      },
      {
        name: 'Splendid Destruction Bomb',
        bundle: 1,
        price: 20,
        url: '/images/splendidDestructionBomb.png',
      },
      {
        name: 'Sleep Bomb',
        bundle: 1,
        price: 14,
        url: '/images/sleepBomb.png',
      },
      {
        name: 'Splendid Sleep Bomb',
        bundle: 1,
        price: 20,
        url: '/images/splendidSleepBomb.png',
      },
      {
        name: 'Sacred Bomb',
        bundle: 1,
        price: 14,
        url: '/images/sleepBomb.png',
      },
      {
        name: 'Splendid Sacred Bomb',
        bundle: 1,
        price: 20,
        url: '/images/splendidSacredBomb.png',
      },
      {
        name: 'Corrosive Bomb',
        bundle: 1,
        price: 14,
        url: '/images/corrosiveBomb.png',
      },
      {
        name: 'Splendid Corrosion Bomb',
        bundle: 1,
        price: 14,
        url: '/images/splendidCorrosionBomb.png',
      },
    ],
    granades: [
      {
        name: 'Whirlwind Granade',
        bundle: 1,
        price: 100,
        url: '/images/whirlwindGranade.png',
      },
      {
        name: 'Splendid Whirlwind Granade',
        bundle: 1,
        price: 100,
        url: '/images/splendidWhirlwindGranade.png',
      },
      {
        name: 'Dark Granade',
        bundle: 1,
        price: 100,
        url: '/images/darkGranade.png',
      },
      {
        name: 'Splendid Dark Granade',
        bundle: 1,
        price: 100,
        url: '/images/splendidDarkGranade.png',
      },
      {
        name: 'Clay Granade',
        bundle: 1,
        price: 100,
        url: '/images/clayGranade.png',
      },
      {
        name: 'Splendid Clay Granade',
        bundle: 1,
        price: 100,
        url: '/images/splendidClayGranade.png',
      },
      {
        name: 'Frost Granade',
        bundle: 1,
        price: 100,
        url: '/images/frostGranade.png',
      },
      {
        name: 'Splendid Frost Granade',
        bundle: 1,
        price: 100,
        url: '/images/splendidFrostGranade.png',
      },
    ],
    others: [
      {
        name: 'Panacea',
        bundle: 1,
        price: 100,
        url: '/images/panacea.png',
      },
      {
        name: 'Splendid Panacea',
        bundle: 1,
        price: 100,
        url: '/images/splendidPanacea.png',
      },
      {
        name: 'Time Stop Potion',
        bundle: 1,
        price: 100,
        url: '/images/timeStopPotion.png',
      },
      {
        name: 'Stimulant',
        bundle: 1,
        price: 100,
        url: '/images/stimulant.png',
      },
      {
        name: 'Atropine Potion',
        bundle: 1,
        price: 100,
        url: '/images/atropinePotion.png',
      },
    ],
    meals: [
      {
        name: "Master's Herb Steak Meal",
        bundle: 10,
        price: 100,
        url: '/images/masterHerbSteakMeal.png',
      },
      {
        name: 'Expert Butter Steak Meal',
        bundle: 10,
        price: 100,
        url: '/images/expertButterSteakMeal.png',
      },
      {
        name: "Artisan's Garlic Steak Meal",
        bundle: 10,
        price: 100,
        url: '/images/artisanGarlicSteakMeal.png',
      },
    ],
  },
  reducers: {
    changeConsumablePrice: (state, {payload: {key, value, name}}) => {
      state[`${key}`] = state[key].map((element: {name: string}) => {
        return element.name === name
          ? {
              ...element,
              price: value,
            }
          : element
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const {changeConsumablePrice} = consumablesSlice.actions

export default consumablesSlice.reducer
